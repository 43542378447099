import NavLinks from '../NavLinks/NavLinks';
import { Col, Row } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { useParams } from "react-router-dom";
import CardProduct from '../Index/component/CardProduct';
import useMediaQuery from '@mui/material/useMediaQuery';
import axiosClient from "../../axios-client";
import { useEffect, useState } from "react";
import AllProductNotFound from '../allProductNotFound/allProductNotFound';
import loader from "./../../Image/loader.gif";

export default function MainCategories() {
    let { slug } = useParams();
    const [sort, setSort] = useState(-1); // Default sorting option
    const [showByAmount, setShowByAmount] = useState(false); // Filter for products with amount > 0
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [name, setName] = useState("");
    const [error, setError] = useState(null);
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleSortChange = (event) => {
        setSort(event.target.value);
    };

    const toggleShowByAmount = () => {
        setShowByAmount(!showByAmount);
    };

    useEffect(() => {
        getProducts(); // Fetch products when slug changes
    }, [slug]);

    useEffect(() => {
        applyFiltersAndSorting(); // Apply filters and sorting whenever products, sort, or showByAmount change
    }, [products, sort, showByAmount]);

    const getProducts = () => {
        setLoading(true);
        axiosClient.get(`/allMainCategories/${slug}`)
            .then(({ data }) => {
                setLoading(false);
                setName(data.data.name);

                // Combine products from all categories and remove duplicates
                const allProducts = data.data.product_category_id.flatMap(category => category.products);
                const uniqueProducts = Array.from(new Map(allProducts.map(product => [product.id, product])).values());

                setProducts(uniqueProducts);
                setLastPage(1); // For demonstration purposes (update with actual pagination data if available)
            })
            .catch(err => {
                setLoading(false);
                if (err.response && err.response.status === 404) {
                    setError('Product Category not found');
                } else {
                    console.error(err);
                }
            });
    };

    const applyFiltersAndSorting = () => {
        let filtered = [...products];

        // Apply the "amount" filter
        if (showByAmount) {
            filtered = filtered.filter(product => product.amount > 0);
        }

        // Apply sorting
        switch (sort) {
            case -1: // Newest
                filtered = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                break;
            case -2: // Most Expensive
                filtered = filtered.sort((a, b) => b.price - a.price);
                break;
            case -3: // Cheapest
                filtered = filtered.sort((a, b) => a.price - b.price);
                break;
            case -4: // Most Popular (assuming a `sales_count` property)
                filtered = filtered.sort((a, b) => b.sales_count - a.sales_count);
                break;
            case -5: // Highest Rated (assuming a `rating` property)
                filtered = filtered.sort((a, b) => b.rating - a.rating);
                break;
            default:
                break;
        }

        setFilteredProducts(filtered);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page); // Update current page state
        // Fetch products for the new page if using server-side pagination
    };

    if (error) {
        return <AllProductNotFound />;
    }
    if (loading) {
        // Display loader while the page is loading
        return (
          <div className="preloader">
            <img src={loader} alt="Loading..." />
          </div>
        );
      }
    return (
        <>
            <div className='d-flex justify-content-between filters-box'>
                <h2>{name}</h2>
                <div className='d-flex'>
                    <div className='d-flex align-items-center switch-box me-1 me-lg-3'>
                        فقط موجود ها
                        <Switch
                            size='small'
                            checked={showByAmount}
                            onChange={toggleShowByAmount}
                            inputProps={{ 'aria-label': 'Amount filter' }}
                            color="primary"
                        />
                    </div>
                    <Select
                        onChange={handleSortChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        className='sort-select'
                        value={sort}
                    >
                        <MenuItem value={-1}>جدیدترین</MenuItem>
                        <MenuItem value={-2}>گرانترین</MenuItem>
                        <MenuItem value={-3}>ارزانترین</MenuItem>
                        <MenuItem value={-4}>پرفروش ترین</MenuItem>
                        <MenuItem value={-5}>امتیاز</MenuItem>
                    </Select>
                </div>
            </div>
            <Row className='row-cols-2 row-cols-lg-3 mt-4 gy-4'>
                {filteredProducts.map((product) => (
                    <Col key={product.id}> {/* Ensure products have unique 'id' */}
                        <CardProduct {...product} />
                    </Col>
                ))}
            </Row>
            {/* <div className='pagination-container'>
                <Pagination
                    className="pagination my-5"
                    count={lastPage} // Total number of pages
                    page={currentPage} // Current active page
                    onChange={(_, page) => handlePageChange(page)} // Page change handler
                    siblingCount={isMobile ? 1 : 2} // Adjust number of visible page items for mobile
                    boundaryCount={1} // Number of items at start and end
                    renderItem={(item) => (
                        <PaginationItem
                            className="custom-pagination-item"
                            slots={{ previous: FiArrowRight, next: FiArrowLeft }} // Custom icons for pagination
                            {...item}
                        />
                    )}
                />
            </div> */}
        </>
    );
}
