import { Col, Container, Row } from 'react-bootstrap';
import "./Cart.css";
import { useState, useEffect } from 'react';
import { CiPercent } from "react-icons/ci";
import { TiDeleteOutline } from "react-icons/ti";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import axiosClient from "../../axios-client";

export default function Cart() {
    const apiKey = process.env.REACT_APP_API_KEY;
    const cartFromCookie = Cookies.get('cart');
    const [cart, setCart] = useState(() => {
        return cartFromCookie ? JSON.parse(cartFromCookie) : [];
    });
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProduct();
    }, [cart]);

    const addProducts = (newProducts) => {
        setProducts(prevProducts => {
            const existingProductIds = new Set(prevProducts.map(p => p.id));
            const filteredNewProducts = newProducts.filter(p => !existingProductIds.has(p.id));
            return [...prevProducts, ...filteredNewProducts];
        });
    };

    const removeCartItem = (productId) => {
        // Filter out the product from the cart
        const updatedCart = cart.filter(item => item.productId !== productId);
    
        // Update the cart in cookies
        Cookies.set('cart', JSON.stringify(updatedCart));
    
        // Update the cart and products states
        setCart(updatedCart);
        setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
    };
    

    const plusCount = (productId) => {
        setProducts(prevProducts => {
            return prevProducts.map(product => {
                if (product.id === productId) {
                    if (product.count < product.amount) {
                        return { ...product, count: product.count + 1 };
                    }
                }
                return product;
            });
        });
    };

    const minesCount = (productId) => {
        setProducts(prevProducts => {
            return prevProducts.map(product => {
                if (product.id === productId && product.count > 1) {
                    return { ...product, count: product.count - 1 };
                }
                return product;
            });
        });
    };

    const getProduct = () => {
        setLoading(true);
        cart.forEach(item => {
            axiosClient.get(`/getCart/${item.productId}`)
                .then(({ data }) => {
                    setLoading(false);
                    addProducts(data.data.map(prod => ({
                        ...prod,
                        count: item.count, // Set initial count from cart data
                    })));
                })
                .catch(() => {
                    setLoading(false);
                });
        });
    };

    return (
        <Container>
            <Row className='mt-4'>
                <Col lg={8}>
                    <h5 className='cart-title mb-5 mt-3'>
                        <span>سبد خرید</span>
                        <span className='box-discount'>
                            <span className='fw-200'>کد تخفیف :</span>
                            <span className=''>
                                <input type="text" placeholder='...' className='input-discount' />
                            </span>
                            <span style={{ color: "#F6A413" }}><CiPercent /></span>
                        </span>
                    </h5>
                    <div>
                        {products.map((ptc, index) => (
                            <Row key={index} className='align-items-center py-3 box-ptc gy-3'>
                                <Col xs={1} lg={1} className='cart-count'>
                                    <span>{index + 1}.</span>
                                </Col>
                                <Col xs={6} lg={2} className='cart-img'>
                                    <img src={apiKey + ptc.photo} className='img-fluid rounded' alt={ptc.name} />
                                </Col>
                                <Col xs={5} lg={2} className='cart-name'>
                                    <span>{ptc.name}</span>
                                </Col>
                                <Col xs={6} lg={2}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='d-lg-none'>قیمت:</span>
                                        <div>
                                            <span className="me-2" style={{ marginTop: "10px" }}>{ptc.price}</span>
                                            <img src="http://localhost:3000/image/toman.svg" style={{ width: "25px" }} alt="" />
                                            <div><span className="main-price">{ptc.mainPrice}</span></div>
                                        </div>
                                    </div>
                                    <div className='d-lg-none d-flex justify-content-between align-items-center btn-count-mobile'>
                                        <span>تعداد:</span>
                                        <div>
                                            <div className='count-btns'>
                                                <button onClick={() => plusCount(ptc.id)} className='border-0 px-2 px-lg-3 bg-transparent'>
                                                    <span style={{ color: "#f6a413" }}>+</span>
                                                </button>
                                                <span>{ptc.count}</span>
                                                <button onClick={() => minesCount(ptc.id)} className='border-0 px-2 px-lg-3 bg-transparent'>
                                                    <span style={{ color: "#f6a413" }}>_</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-lg-none d-flex justify-content-between align-items-center'>
                                        <span>جمع:</span>
                                        <div><span>{ptc.price * ptc.count}</span></div>
                                    </div>
                                </Col>
                                <Col xs={6} lg={2} className='d-none d-lg-block'>
                                    <div xs={3} className='count-btns'>
                                        <button onClick={() => plusCount(ptc.id)} className='border-0 px-lg-3 bg-transparent'>
                                            <span style={{ color: "#f6a413" }}>+</span>
                                        </button>
                                        <span>{ptc.count}</span>
                                        <button onClick={() => minesCount(ptc.id)} className='border-0 px-lg-3 bg-transparent'>
                                            <span style={{ color: "#f6a413" }}>_</span>
                                        </button>
                                    </div>
                                </Col>
                                <Col xs={6} lg={2} className='text-center d-none d-lg-block'>
                                    <span>{ptc.price * ptc.count}</span>
                                </Col>
                                <Col xs={6} lg={1} className='cart-remove'>
                                    <span onClick={() => removeCartItem(ptc.id)}
                                        style={{ cursor: 'pointer' }} className='text-danger fs-4'>
                                        <TiDeleteOutline />
                                    </span>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Col>
                <Col lg={4} className='mt-5 mt-lg-0'>
                    <div className='box-information'>
                        <div className='d-flex justify-content-between my-5'>
                            <span>مجموع ایتم ها :</span>
                            <span>{products.length}</span>
                        </div>
                        <div className='d-flex justify-content-between my-5'>
                            <span>تعداد ایتم ها :</span>
                            <span>{products.reduce((total, product) => total + product.count, 0)}</span>
                        </div>
                        <div className='d-flex justify-content-between my-5'>
                            <span>درصد کد تخفیف :</span>
                            <span>0</span>
                        </div>
                        <div className='d-flex justify-content-between my-5'>
                            <span>مبلغ تخفیف :</span>
                            <span>0</span>
                        </div>
                        <div className='d-flex justify-content-between my-5'>
                            <span>مبلغ سبد خرید :</span>
                            <span>
                                {products.reduce((total, product) => total + product.price * product.count, 0).toLocaleString()} تومان
                            </span>
                        </div>
                        <div>
                            <Link to={"/pardakht"}>
                                <button className='countine-btn'>
                                    ادامه
                                </button>
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='my-5'>
                <Col>
                    <ul style={{ borderRight: "solid 2px #F6A413" }}>
                        <li className='fs-4 my-3' style={{ listStyle: "none", color: "red" }}>توجه</li>
                        <li>
                            <p>هزینه ارسال بصورت پس کرایه میباشد</p>
                        </li>
                        <li>
                            <p>با توجه به حجم بالای سفارشات ممکن است سفارشات تا 48 ساعت پس از ثبت سفارش ارسال شوند. از صبر و شکیبایی شما سپاس گزاریم.</p>
                        </li>
                        <li>
                            <p>با توجه به اتصال درگاه های پرداختی مجموعه به شبکه مالیاتی کشور در صورت لغو سفارش پس از پرداخت و ثبت آن مقدار 9% از مبلغ کل سفارش کسر شده و مابقی مبلغ به حساب شما واریز می گردد. از این رو از شما عزیزان خواهشمندیم در خرید خود دقت فرمایید.</p>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}
