import NavLinks from '../NavLinks/NavLinks'
import { Col, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import { useParams } from "react-router-dom";
import CardProduct from '../Index/component/CardProduct'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import useMediaQuery from '@mui/material/useMediaQuery';
import axiosClient from "../../axios-client";
import { useEffect, useState } from "react";
import NotFound from '../NotFound/NotFound';
import AllProductNotFound from '../allProductNotFound/allProductNotFound';
import loader from "./../../Image/loader.gif";

export default function ProductCategories() {
    let { categorySlug } = useParams();
    const [sort, setSort] = useState(-1); // Default sort value
    const [showInStock, setShowInStock] = useState(false); // Filter for in-stock items
    const [showByAmount, setShowByAmount] = useState(false); // Filter for products with amount greater than 0
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [name, setName] = useState("");
    const [error, setError] = useState(null);

    const handleSortChange = (event) => {
        setSort(event.target.value);
    };

    const toggleShowInStock = () => {
        setShowInStock(!showInStock);
    };

    useEffect(() => {
        getProducts();
    }, [categorySlug]);

    useEffect(() => {
        filterProducts();
    }, [products, showInStock, showByAmount, sort]);

    const getProducts = () => {
        setLoading(true);
        axiosClient.get(`/getProductCategories/${categorySlug}`)
            .then(({ data }) => {
                setLoading(false);
                setProducts(data.data.products);
                setName(data.data);
            })
            .catch(err => {
                if (err.response && err.response.status === 404) {
                    setError('Product Category not found');
                } else {
                    console.error(err);
                }
            });
    };


    const filterProducts = () => {
        let filtered = [...products];

        if (showInStock) {
            filtered = filtered.filter(product => product.amount > 0);
        }

        // Apply sorting
        switch (sort) {
            case -1: // Newest
                filtered = filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                break;
            case -2: // Highest price
                filtered = filtered.sort((a, b) => b.price - a.price);
                break;
            case -3: // Lowest price
                filtered = filtered.sort((a, b) => a.price - b.price);
                break;
            case -4: // Most popular
                filtered = filtered.sort((a, b) => b.sales_count - a.sales_count);
                break;
            case -5: // Highest rating
                filtered = filtered.sort((a, b) => b.rating - a.rating);
                break;
            default:
                break;
        }

        setFilteredProducts(filtered);
    };

    if (error) {
        return <AllProductNotFound />;
    }
    if (loading) {
        // Display loader while the page is loading
        return (
          <div className="preloader">
            <img src={loader} alt="Loading..." />
          </div>
        );
      }
    return (
        <>
            <div className='d-flex justify-content-between filters-box'>
                <h2>{name.name}</h2>
                <div className='d-flex'>
                    <div className='d-flex align-items-center switch-box me-1 me-lg-3'>
                        فقط موجود ها
                        <Switch
                            size='small'
                            checked={showInStock}
                            onChange={toggleShowInStock}
                            inputProps={{ 'aria-label': 'In-stock filter' }}
                            color="warning"
                        />
                    </div>

                    <Select
                        onChange={handleSortChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        className='sort-select'
                        value={sort}
                    >
                        <MenuItem value={-1}>جدیدترین</MenuItem>
                        <MenuItem value={-2}>گرانترین</MenuItem>
                        <MenuItem value={-3}>ارزانترین</MenuItem>
                        <MenuItem value={-4}>پرفروش ترین</MenuItem>
                        <MenuItem value={-5}>امتیاز</MenuItem>
                    </Select>
                </div>
            </div>
            <Row className='row-cols-2 row-cols-lg-3 mt-4 gy-4'>
                {filteredProducts.map((product, index) => (
                    <Col key={index}>
                        <CardProduct {...product} />
                    </Col>
                ))}
            </Row>
            <div className='mt-5'>
                <div style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: name.article }} />
            </div>
        </>
    );
}