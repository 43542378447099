import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaWhatsapp, FaPhone, FaInstagram, FaTelegram } from "react-icons/fa";
import { SiAparat, SiYoutube } from "react-icons/si";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoIosCard } from "react-icons/io";
import { GrArticle } from "react-icons/gr";
import { MdConstruction } from "react-icons/md";
import axiosClient from "../../axios-client";
import enamadImg from "../../Image/logo (1) 1.png";
import majazi from "../../Image/majazi 1.png";
import samandehi from "../../Image/samandehi 1.png";
import "./Footer.css";

export default function Footer() {
    const [mainCategory, setMainCategory] = useState([]);

    useEffect(() => {
        getMainCategory();
    }, []);

    const getMainCategory = () => {
        axiosClient.get("/getMainCategories")
            .then(({ data }) => {
                setMainCategory(data.data);
            });
    };

    return (
        <footer>
            <Container className="footer p-4 p-lg-5">
                <Row className="gx-lg-6">
                    {/* Company Info */}
                    <Col xs={6} lg={5} className="phone-line">
                        <Row className="align-items-center F-title position-relative">
                            <Col sm={6} lg={12}>
                                <span className="main-color fw-bold logo-footer fs-1">آپگریدر</span>
                            </Col>
                            <Col sm={6} lg={12} className="text-end text-lg-center">
                                <span className="fs-5 footer-p">مرجع تخصصی تقویت و تعمیر موتور ef7</span>
                            </Col>
                        </Row>
                        <div className="py-4">
                            <p className="fs-7 footer-p" style={{ textAlign: "justify" }}>
                                آپگریدر مجموعه‌ای تخصصی است که با تمرکز و اشراف کامل بر روی موتورهای EF7، خدمات تعمیر و تقویت این نوع موتور را به شما عزیزان ارائه می‌دهد.
                            </p>
                        </div>
                        <div className="d-none d-md-block">
                            <Row className="gx-0 align-items-around">
                                <Col xs={6}>
                                    <h3 className="fs-5 m-0">با ما در تماس باشید</h3>
                                </Col>
                                <Col xs={6}>
                                    <div className="text-end">
                                        <div className="fs-3 text-secondary position-relative F-phone pb-1">
                                            021<span className="main-color me-2">28429102</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="F-title position-relative after-two"> </Col>
                                <Col xs={12}>
                                    <div className="mt-1 text-center">
                                        <span className="mx-3">
                                            <a href="https://instagram.com/upgrader.ir" target="_blank" rel="noopener noreferrer">
                                                <FaInstagram className="text-danger fs-4" />
                                            </a>
                                        </span>
                                        <span className="mx-2">
                                            <a href="https://t.me/Upgrader_Official" target="_blank" rel="noopener noreferrer">
                                                <FaTelegram className="text-primary fs-4" />
                                            </a>
                                        </span>
                                        <span className="mx-2">
                                            <a href="https://www.aparat.com/Upgrader/" target="_blank" rel="noopener noreferrer">
                                                <SiAparat className="text-danger fs-4" />
                                            </a>
                                        </span>
                                        <span className="mx-2">
                                            <a href="https://www.youtube.com/@Upgrader-ir" target="_blank" rel="noopener noreferrer">
                                                <SiYoutube className="text-danger fs-4" />
                                            </a>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    {/* Product Categories */}
                    <Col xs={6} lg={5}>
                        <div>
                            <h6 className="main-color py-1 pb-lg-3">دسته بندی محصولات</h6>
                            <ul className="row row-cols-2 row-cols-lg-3 gy-3 F-category p-0">
                                {mainCategory.map((category, index) => (
                                    <Link to={`/product-category/${category.slug}/`} key={index}>
                                        <li>{category.name}</li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h5 className="main-color py-1 py-lg-3">دسترسی سریع</h5>
                            <ul className="row row-cols-2 row-cols-lg-3 gy-3 p-0 quik-access" style={{ listStyle: "none" }}>
                                <Link to="/contact-us">
                                    <li><FaPhone className="main-color me-1" /> تماس باما</li>
                                </Link>
                                <Link to="/introduction/">
                                    <li><AiOutlineExclamationCircle className="main-color me-1" /> درباره ما </li>
                                </Link>
                                <Link to="/reservation">
                                    <li><IoIosCard className="main-color me-1" /> رزرو وقت </li>
                                </Link>
                                <Link to="/category/خدمات-مجموعه-آپگریدر/">
                                    <li><MdConstruction className="main-color me-1" /> خدمات مجموعه </li>
                                </Link>
                                <Link to="/blog">
                                    <li><GrArticle className="main-color me-1" /> مقالات </li>
                                </Link>
                            </ul>
                        </div>
                    </Col>

                    {/* Trust Seals */}
                    <Col lg={2}>
                        <Row className="row-cols-lg-1 h-100">
                            <Col className="text-center">
                                <a href="https://trustseal.enamad.ir/?id=213582&Code=oit53jJfSoGc37gz0mYz" target="_blank" rel="noopener noreferrer">
                                    <img alt="enamad" src={enamadImg} />
                                </a>
                            </Col>
                            <Col className="text-center">
                                <img alt="majazi" src={majazi} />
                            </Col>
                            <Col className="text-center">
                                <img alt="samandehi" src={samandehi} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
