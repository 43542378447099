import { useState } from 'react';

import { PiShoppingCartSimple } from "react-icons/pi";
import { Link } from 'react-router-dom';

import Rating from '@mui/material/Rating';
import toman from "./../../../Image/toman.svg"

export default function CardProduct(props) {
    const apiKey = process.env.REACT_APP_API_KEY;

    const [value, setValue] = useState(3);

    return (
        <>
            <Link to={`/product/${props.slug}`}>
                <div className='slidee slidee2'>
                    <div className='product-card product-card2'>
                        <div><img src={apiKey + props.photo} className='img-fluid card-img rounded' alt={props.name} /></div>
                        <div className='mb-3 text-center mt-3 product-title-cart'>{props.name}</div>
                        <div className='text-center'>
                            <Rating
                                style={{ direction: "ltr" }}
                                readOnly
                                name="simple-controlled"
                                value={
                                    props.product_comments.length > 0
                                        ? props.product_comments.reduce((sum, comment) => sum + comment.rate, 0) / props.product_comments.length
                                        : 0 // Default to 0 if there are no comments
                                }
                            />
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <Link className="f-link"><PiShoppingCartSimple className="links-card" /></Link>
                            </div>
                            <div>
                                <span className="f-links d-flex align-items-center">
                                    <span className="f-links d-flex align-items-center">
                                        {props.amount > 0 && props.price > 0 ?
                                            <>

                                                <span className="me-2" style={{ marginTop: "10px" }}>{props.price}</span> <img src="http://localhost:3000/image/toman.svg" style={{ width: "25px" }} alt="" />
                                            </>
                                            :
                                            <>
                                                <span className='text-danger text-sm'>تماس بگیرید</span>
                                            </>
                                        }

                                    </span>
                                </span>
                                <div><span className="main-price">{props.discount}</span></div>
                            </div>

                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}
