import { Col, Row } from 'react-bootstrap';
import "./AllProducts.css";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import CardProduct from '../Index/component/CardProduct';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import useMediaQuery from '@mui/material/useMediaQuery';
import axiosClient from "../../axios-client";
import { useEffect, useState } from "react";
import loader from "./../../Image/loader.gif";

export default function AllProducts() {
    const [sort, setSort] = useState(-1); // Sorting option
    const [onlyInStock, setOnlyInStock] = useState(false); // In-stock filter
    const [products, setProducts] = useState([]); // Products list
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [lastPage, setLastPage] = useState(1); // Last page
    const [loading, setLoading] = useState(false); // Loading state
    const isMobile = useMediaQuery('(max-width:600px)'); // Responsive query

    const label = { inputProps: { 'aria-label': 'Color switch demo' } };

    useEffect(() => {
        getProducts(currentPage, sort, onlyInStock);
        document.title = 'همه محصولات فروشگاه آپگریدر - فروشگاه آپگریدر | ارسال به تمام ایران';
    }, [currentPage, sort, onlyInStock]);

    const getProducts = (page, sortOption, inStock) => {
        setLoading(true);
        axiosClient
            .get(`/getProducts`, {
                params: {
                    page,
                    sort: sortOption,
                    only_in_stock: inStock,
                },
            })
            .then(({ data }) => {
                setLoading(false);
                setProducts(data.data);
                setCurrentPage(data.meta.current_page);
                setLastPage(data.meta.last_page);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSortChange = (event) => {
        setSort(event.target.value);
        setCurrentPage(1);
    };

    const handleStockToggle = (event) => {
        setOnlyInStock(event.target.checked);
        setCurrentPage(1);
    };
    if (loading) {
        // Display loader while the page is loading
        return (
          <div className="preloader">
            <img src={loader} alt="Loading..." />
          </div>
        );
      }
    return (
        <>
            <div className='d-flex align-items-center justify-content-between filters-box'>
                <h2 className='title-shop-page'>همه محصولات</h2>
                <div className='d-flex'>
                    <div className='d-flex align-items-center swich-box me-1 me-lg-3'>
                        فقط موجود ها
                        <Switch
                            size="small"
                            {...label}
                            color="warning"
                            checked={onlyInStock}
                            onChange={handleStockToggle}
                        />
                    </div>
                    <Select
                        onChange={handleSortChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        className='sort-select'
                        defaultValue={-1}
                    >
                        <MenuItem value={-1}>جدیدترین </MenuItem>
                        <MenuItem value={-2}>گرانترین</MenuItem>
                        <MenuItem value={-3}>ارزانترین</MenuItem>
                        <MenuItem value={-4}>پرفروش ترین</MenuItem>
                        <MenuItem value={-5}>امتیاز</MenuItem>
                    </Select>
                </div>
            </div>
            <Row className='row-cols-2 row-cols-lg-3 mt-4 gy-4'>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    products.map((product, index) => (
                        <Col key={index}>
                            <CardProduct {...product} />
                        </Col>
                    ))
                )}
            </Row>
            <div className='pagination-container'>
                <Pagination
                    className="pagination my-5"
                    count={lastPage}
                    page={currentPage}
                    onChange={(_, page) => handlePageChange(page)}
                    siblingCount={isMobile ? 1 : 2}
                    boundaryCount={1}
                    renderItem={(item) => (
                        <PaginationItem
                            className="custom-pagination-item"
                            slots={{ previous: FiArrowRight, next: FiArrowLeft }}
                            {...item}
                        />
                    )}
                />
            </div>
        </>
    );
}
